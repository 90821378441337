<template>
    <button
        type="button"
        class="default-phone-handler"
        :class="{ 'default-phone-handler_active': isActive }"
        @click.stop.prevent="$emit('handle')"
    >
        <DefaultPhoneFlag v-if="iso2" :iso2="iso2" />
        <div
            v-else
            style="width: 22px; background-color: #eee; height: 22px; border-radius: 50%; margin-right: 4px"
        ></div>
        <DefaultPhoneChevron />
    </button>
</template>

<script>
import { iso2 } from "../../../Forms/country/data/default-country"
import DefaultPhoneFlag from "./DefaultPhoneFlag"
import DefaultPhoneChevron from "./DefaultPhoneChevron"

export default {
    components: { DefaultPhoneChevron, DefaultPhoneFlag },
    props: {
        iso2: {
            type: String,
            default: iso2
        },
        isActive: {
            type: Boolean,
            default: false
        }
    },
    name: "DefaultPhoneHandler"
}
</script>

<style scoped lang="sass">
.default-phone-handler
    display: flex
    align-items: center
    padding: 2px 4px
    cursor: pointer
    width: max-content
    transition: .2s
    border-radius: 100px
    border: none
    background-color: #fff
    &_active, &:hover
        box-shadow: 0 2px 5px rgb(128 158 191 / 25%)
    svg
        transition: .2s
    .default-phone-flag
        margin-right: 4px
</style>
