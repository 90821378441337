<template>
    <div class="default-countries">
        <div class="custom-scroll default-countries__scroll">
            <template v-if="withAbsent">
                <div
                    :class="{ 'default-country_active': iso2 === null }"
                    @click="$emit('select', null)"
                    class="default-country"
                >
                    <div style="margin-left: 0" class="default-country__title">
                        {{ translate("settings.not_selected") }}
                    </div>
                </div>
                <div
                    :class="{ 'default-country_active': iso2 === 'auto' }"
                    @click="$emit('select', { iso2: 'auto', translate: 'settings.users_country' })"
                    class="default-country default-country_absent"
                >
                    <div class="default-country__title">
                        {{ translate("settings.users_country") }}
                    </div>
                </div>
            </template>

            <flex-container align="center" class="mb-20">
                <svg
                    class="mr-20 ml-5"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M19.4697 20.5303C19.7626 20.8232 20.2374 20.8232 20.5303 20.5303C20.8232 20.2374 20.8232 19.7626 20.5303 19.4697L19.4697 20.5303ZM17.25 10.5C17.25 14.2279 14.2279 17.25 10.5 17.25V18.75C15.0563 18.75 18.75 15.0563 18.75 10.5H17.25ZM10.5 17.25C6.77208 17.25 3.75 14.2279 3.75 10.5H2.25C2.25 15.0563 5.94365 18.75 10.5 18.75V17.25ZM3.75 10.5C3.75 6.77208 6.77208 3.75 10.5 3.75V2.25C5.94365 2.25 2.25 5.94365 2.25 10.5H3.75ZM10.5 3.75C14.2279 3.75 17.25 6.77208 17.25 10.5H18.75C18.75 5.94365 15.0563 2.25 10.5 2.25V3.75ZM20.5303 19.4697L16.3428 15.2821L15.2821 16.3428L19.4697 20.5303L20.5303 19.4697Z"
                        fill="#3E4755"
                    />
                </svg>

                <DefaultInput
                    @update="search = $event"
                    :value="search"
                    class="mr-10"
                    :placeholder="translate('expert.search') || 'Search'"
                    style-modifier="v_2"
                />
            </flex-container>
            <div
                class="default-country"
                :class="{
                    'default-country_active':
                        (country && country.iso2 === iso2) ||
                        (selectedCountries && selectedCountries.find(c => country && c.iso2 === country.iso2))
                }"
                :key="index"
                v-for="(country, index) in countries"
                @click="handleCountry(country)"
            >
                <div style="align-items: center" class="flex items-center">
                    <div v-if="country && (country.iso2 || country.iso_2)" class="default-country-flag">
                        <img
                            :alt="country.iso2"
                            loading="lazy"
                            :src="`${domain()}/build/img/flags/${country.iso2 || country.iso_2.toLowerCase()}.svg`"
                        />
                    </div>

                    <div class="default-country__title">
                        {{ country.name }}
                    </div>
                </div>
                <div v-if="country.dialCode" class="default-country__title">+{{ country.dialCode }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import FlexContainer from "../../Containers/FlexContainer.vue"
import { iso2 } from "../../Forms/country/data/default-country"
import countriesList from "../../Forms/country/data/countries"
import DefaultInput from "../../Forms/DefaultInput.vue"

export default {
    components: { FlexContainer, DefaultInput },
    props: {
        cases: {
            type: [Object, Array],
            default: null
        },
        iso2: {
            type: String,
            default: iso2
        },
        selectedCountries: {
            type: [Array, Boolean],
            default: () => []
        },
        include: {
            type: [Array, Boolean],
            default: () => []
        },
        exclude: {
            type: [Array, Boolean],
            default: () => []
        },
        withAbsent: {
            type: Boolean,
            default: false
        }
    },
    name: "DefaultCountriesList",
    data() {
        return {
            search: ""
        }
    },
    methods: {
        domain() {
            return window.app_subdomain_url_no_locale || "https://kwiga.com/"
        },
        handleCountry(country) {
            this.$emit("select", country)
            this.search = ""
        },
        translate(key) {
            if (this.t && this.$store) {
                return this.t(key)
            } else {
                return ""
            }
        }
    },
    computed: {
        countries() {
            let countries = this.cases || countriesList

            if (this.include && this.include.length) {
                countries = countries.filter(country =>
                    this.include.find(
                        c =>
                            (country && c.iso2 === country.iso2) ||
                            (country && country.iso_2 && c.iso2 === country.iso_2.toLowerCase())
                    )
                )
            }

            if (this.exclude && this.exclude.length) {
                countries = countries.filter(
                    country =>
                        !this.exclude.find(
                            c =>
                                (country && c.iso2 === country.iso2) ||
                                (country && country.iso_2 && c.iso2 === country.iso_2.toLowerCase())
                        )
                )
            }

            if (this.search) {
                return countries.filter(
                    country =>
                        country.name.toLowerCase().includes(this.search.toLowerCase()) ||
                        (country.dialCode && country.dialCode.includes(this.search))
                )
            }

            return countries.filter(c => !!c)
        }
    }
}
</script>

<style scoped lang="sass">
.default-countries
    position: absolute
    top: calc(100% + 6px)
    width: 375px
    z-index: 5
.default-country-flag
    width: 22px
    min-width: 22px
    height: 22px
    border-radius: 50%
    overflow: hidden
    display: flex
    align-items: center
    justify-content: center
    img
        width: 22px
        height: 22px
.default-countries
    background: #fff
    box-shadow: 0 10px 40px rgba(213, 221, 230, 0.6)
    border-radius: 10px
    padding: 7px 5px 7px 10px
    .default-country
        display: flex
        width: calc(100% - 7px)
        align-items: center
        justify-content: space-between
        min-height: 40px
        margin-bottom: 2px
        transition: .2s
        cursor: pointer
        padding-left: 14px
        padding-right: 12px
        border-radius: 5px
        margin-right: 2px
        &_absent
            position: relative
            margin-bottom: 30px
            &::after
                content: ''
                position: absolute
                bottom: -10px
                height: 1px
                left: 0
                background-color: #F3F3F3
                width: 100%
            .default-country__title
                margin-left: 0

        &:hover
            background: #F2F7FF
        &_active
            background: #F2F7FF
        &__title
            font-size: 13px
            line-height: 13px
            color: #2B2B2B
            font-weight: 400
            margin-left: 30px
    &__list, &__scroll
        max-height: 200px
        overflow: auto
</style>
