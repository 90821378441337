<template>
    <errors-container
        :color="visibleValidation && visibleValidation.color"
        class="default-phone-input-container"
        :errors="errors"
    >
        <div
            class="default-phone-input"
            :style="getStyles()"
            :class="{ 'default-phone-input_active': focused, 'default-phone-input_error': errors.length }"
        >
            <div v-if="label" class="default-phone-input__label">{{ label }}<em v-if="required">*</em></div>
            <div v-click-outside="hideModal" class="default-phone-input-handler-container">
                <DefaultPhoneHandler
                    v-if="visibleFormat && visibleFormat.display_country"
                    @handle="isModalVisible = !isModalVisible"
                    :iso2="currentCountry && currentCountry.iso2"
                    :is-active="isModalVisible"
                />
                <transition name="fade-up">
                    <DefaultPhoneCountries
                        @select="currentCountry = $event"
                        :iso2="currentCountry && currentCountry.iso2"
                        :exclude="visibleFormat && visibleFormat.exclude_countries"
                        :include="visibleFormat && visibleFormat.include_countries"
                        v-if="isModalVisible"
                    />
                </transition>
            </div>
            <input
                v-if="required && phoneModel"
                type="text"
                style="height: 1px; padding: 0; margin: 0; outline: none; position: absolute; opacity: 0"
                :required="required"
                :name="name"
                :value="
                    phoneModel.replaceAll(
                        `${currentCountry && currentCountry.dialCode && `+${currentCountry.dialCode}`}`,
                        ''
                    )
                "
            />

            <input
                @blur="focused = false"
                @focus="focused = true"
                @input="phoneModel = $event.target.value"
                :value="phoneModel"
                :style="{ paddingLeft: visibleFormat && visibleFormat.display_country ? '9px' : '0' }"
                ref="input"
                :id="`${uuid}-phone`"
                :placeholder="placeholder"
                type="text"
                :name="name"
                class="default-phone-input__input"
            />
        </div>
    </errors-container>
</template>

<script>
import countries from "../../Forms/country/data/countries"
import { dialCode, iso2, name } from "../country/data/default-country"
import DefaultPhoneHandler from "./components/DefaultPhoneHandler"
import DefaultPhoneCountries from "../country/DefaultCountriesList.vue"
import ClickOutside from "vue-click-outside"
import ErrorsContainer from "../../Containers/ErrorsContainer.vue"

export default {
    components: { ErrorsContainer, DefaultPhoneCountries, DefaultPhoneHandler },
    props: {
        defaultCode: {
            type: String,
            default: iso2
        },
        placeholder: {
            type: String,
            default: ""
        },
        uuid: {
            type: String,
            default: ""
        },
        inputStyle: {
            type: Object,
            default: () => {}
        },
        format: {
            type: [Object, Array],
            default: null
        },
        validation: {
            type: [Object, Array],
            default: null
        },
        value: {
            type: String,
            default: ""
        },
        name: {
            type: String,
            default: "phone"
        },
        label: {
            type: String,
            default: ""
        },
        required: {
            type: Boolean,
            default: false
        },
        defaultValidation: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
            default: () => []
        }
    },
    model: {
        prop: "value",
        event: "update"
    },
    directives: {
        ClickOutside
    },
    name: "DefaultPhoneInput",
    data() {
        return {
            currentCountry: {
                iso2,
                dialCode,
                name
            },
            isModalVisible: false,
            disableInsertion: false,
            disableFocus: true,
            focused: false,
            defaults: {
                validation: null,
                format: null
            }
        }
    },
    async created() {
        if (this.defaultValidation) {
            this.$set(this.defaults, "format", {
                default_country: { iso2: "auto", translate: "settings.users_country" },
                display_country: true,
                include_countries: [],
                exclude_countries: []
            })
            this.$set(this.defaults, "validation", {
                color: "#DD4141",
                text: null,
                regex: `^[\\+0-9]{1,30}$`,
                country_code_required: false,
                check_phone: false,
                min: null,
                max: null
            })
        }

        this.setDefaults()
    },
    methods: {
        setDefaults() {
            if (this.value && this.value.length) {
                this.disableInsertion = true
            }

            if (this.visibleFormat && this.visibleFormat.display_country) {
                if (this.visibleFormat.default_country && this.visibleFormat.default_country.iso2 !== "auto") {
                    this.currentCountry = this.visibleFormat.default_country
                } else if (this.visibleFormat?.default_country?.iso2 === "auto") {
                    const iso2 = window.country ? window.country.toLowerCase() : this.defaultCode
                    this.currentCountry = countries.find(country => country.iso2 === iso2)
                } else {
                    this.currentCountry = null
                }
            }

            this.$nextTick(() => {
                this.disableInsertion = false
                this.disableFocus = false
            })
        },
        getStyles() {
            let style = {
                borderColor: this.errors.length ? this.visibleValidation && this.visibleValidation.color : "#E0E0EC"
            }

            if (this.inputStyle) {
                style = {
                    ...style,
                    ...this.inputStyle
                }
            }

            delete style["padding"]
            delete style["display"]

            return style
        },
        hideModal() {
            this.isModalVisible = false
        }
    },
    computed: {
        visibleValidation() {
            return this.validation || this.defaults.validation
        },
        visibleFormat() {
            return this.format || this.defaults.format
        },
        phoneModel: {
            get() {
                return this.value
            },
            set(val) {
                for (let i = 0; i < countries.length; i++) {
                    if (val.includes(`+${countries[i].dialCode}`)) {
                        this.disableInsertion = true
                        this.currentCountry = countries[i]
                        this.$nextTick(() => {
                            this.disableInsertion = false
                        })
                    }
                }

                const code = this.currentCountry && `+${this.currentCountry.dialCode}`

                if (
                    this.visibleValidation &&
                    this.visibleValidation.country_code_required &&
                    this.currentCountry &&
                    code &&
                    this.value &&
                    this.value.slice(0, code.length) === code &&
                    val.length < this.value.length &&
                    val.length < code.length &&
                    code !== val &&
                    !this.disableInsertion
                ) {
                    if (!val) {
                        this.$emit("update", code)
                    }
                    this.$forceUpdate()
                    return
                }
                this.$emit("update", val)
            }
        }
    },
    watch: {
        format: {
            deep: true,
            handler() {
                this.setDefaults()
            }
        },
        currentCountry(country) {
            if (!country) {
                return
            }
            if (this.isModalVisible) {
                this.hideModal()
            }
            if (!this.disableInsertion) {
                this.phoneModel = `+${country.dialCode}`
                if (!this.disableFocus) {
                    this.$nextTick(() => {
                        this.$refs.input.focus()
                    })
                }
            }
        },
        isModalVisible(val) {
            if (val) {
                setTimeout(() => {
                    const county = document.querySelector(".default-phone-county_active")
                    if (county) {
                        county.scrollIntoView()
                    }
                }, 0)
            }
        }
    }
}
</script>

<style scoped lang="sass">
.default-phone-input
    padding-left: 17px
    padding-right: 4px
    display: flex
    align-items: center
    border: 1px solid #E0E0EC
    border-radius: 8px
    position: relative
    transition: .2s
    font-size: 13px
    min-height: 45px
    font-size: 13px
    &_active:not(&_error)
        border-color: #C9DEFF !important
    &_error
        border-color: #DD4141
    &__label
        color: #B5C1D2
        font-size: 10px
        line-height: 13px
        font-weight: 600
        padding: 0 4px
        background: #fff
        transform: translateY(-50%)
        position: absolute
        top: 0
        left: 28px
        em
            color: #DD4141
    &__input
        font-size: inherit
        font-weight: 500
        line-height: 17px
        color: #52565C
        width: 100%
        padding: 10px 13px 10px 9px
        border: none
        &:focus-visible
            outline: none
    .default-phone-input-handler-container
        position: relative
</style>
