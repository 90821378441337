<template>
    <div class="default-phone-flag">
        <img :alt="iso2" loading="lazy" :src="`${domain()}build/img/flags/${iso2}.svg`" />
    </div>
</template>

<script>
import { iso2 } from "../../country/data/default-country"

export default {
    props: {
        iso2: {
            type: String,
            default: iso2
        }
    },
    name: "DefaultPhoneFlag",
    methods: {
        domain() {
            return window.app_subdomain_url_no_locale || "https://kwiga.com/"
        }
    }
}
</script>

<style scoped lang="sass">
.default-phone-flag
    width: 22px
    min-width: 22px
    height: 22px
    border-radius: 50%
    overflow: hidden
    display: flex
    align-items: center
    justify-content: center
    img
        width: 22px
        height: 22px
</style>
